import { useState, useEffect } from 'react'
import './App.css'
import logo from './logo.png'

function App() {
  const [usdc, setUsdc] = useState(null)
  const [wbnb, setWbnb] = useState(null)
  const [dyp, setDyp] = useState(null)
  const [bnb, setBnb] = useState(null)
  const [date, setDate] = useState(null)

  const coins = ['usd-coin', 'wbnb', 'defi-yield-protocol', 'binancecoin']

  const fetchData = async () => {
    // let _coins = [];
    coins.map(async (coin) => {
      await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=usd`
      )
        .then((res) => res.json())
        .then((_data) => {
          if (coin === 'usd-coin') {
            setUsdc(_data[coin].usd)
          } else if (coin === 'wbnb') {
            setWbnb(_data[coin].usd)
          } else if (coin === 'defi-yield-protocol') {
            setDyp(_data[coin].usd)
          } else if (coin === 'binancecoin') {
            setBnb(_data[coin].usd)
          }
        })
    })
  }

  function getYear() {
    const date = new Date()
    return date.getFullYear()
  }

  useEffect(() => {
    let year = getYear()
    setDate(year)
    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='App'>
      <div className='wrapper'>
        <img className='App-logo' src={logo} alt='logo' />
        <div style={{ textAlign: 'center' }}>
          <p>
            This content is intended for members of the SkyBison Version One
            fund only. Information on this page is private and confidential.
          </p>

          <div className='bnb-price'>
            $
            {Math.round(
              ((usdc * 150000 + wbnb * 36.77 + dyp * 176813.75) / 430000) * 100
            ) / 100}
            {console.log((usdc + wbnb + dyp) / 430000)}
          </div>
          <p>Current USD Value Per Unit</p>
          <p>
            (value excludes <span style={{ background: "red" }}>BNB</span> for
            now and is approximate)
          </p>
          <p>Holdings</p>
        </div>
        <table className='table '>
          <tbody style={{ textAlign: 'start' }}>
            <tr className='table-row'>
              <td style={{ paddingRight: '20px' }}>USDC</td>
              <td>150,000</td>
              <td>{usdc}/unit</td>
              <td>USD {Math.round(usdc * 150000).toLocaleString('en-US')}</td>
            </tr>
            <tr>
              <td>WBNB</td>
              <td>36.77</td>
              <td>{wbnb}/unit</td>
              <td>USD {Math.round(wbnb * 36.77).toLocaleString('en-US')}</td>
            </tr>
            <tr>
              <td>DYP</td>
              <td>176,813.75</td>
              <td>{dyp}/unit</td>
              <td>USD {Math.round(dyp * 176813.75).toLocaleString('en-US')}</td>
            </tr>
            <tr>
              <td>BNB</td>
              <td>1152.48</td>
              <td>{bnb}/unit</td>
              <td style={{ background: 'red', display: 'inline' }}>
                USD {Math.round(bnb * 1152.48).toLocaleString('en-US')}**
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          ** BNB is held by Binance and is not currently convertible to USD or
          withdrawable. This is temporary and we will keep you updated.
        </p>
        <p style={{ fontSize: '20px', fontWeight: '900' }}>Relevant News</p>
        <p>
          <a
            href='https://news.yahoo.com/crypto-giant-binance-hit-us-213219663.html'
            target='_blank'
            rel='noreferrer'>
            https://news.yahoo.com/crypto-giant-binance-hit-us-213219663.html
          </a>
        </p>{' '}
        <p>
          {' '}
          <a
            href='https://www.siliconrepublic.com/business/binance-us-sued-commodity-futures-trading-derivatives-crypto'
            target='_blank'
            rel='noreferrer'>
            https://www.siliconrepublic.com/business/binance-us-sued-commodity-futures-trading-derivatives-crypto
          </a>
        </p>
        <div style={{ textAlign: 'center' }}>
          <p>© 2020-{date} SkyBison LLC</p>
        </div>
      </div>
    </div>
  )
}

export default App
